import React from 'react';
import { SmileOutlined } from '@ant-design/icons';
import {  notification } from 'antd';
const useNotification  = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (title, description) => {
    api.open({
        message: title,
        description: description,
      icon: (
        <SmileOutlined
          style={{
            color: '#108ee9',
          }}
        />
      ),
      className: '',
    });
  };
  return { openNotification, contextHolder };
};
export default useNotification;