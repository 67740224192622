import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import './booking.css';

import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import { Checkbox, DatePicker, InputNumber } from 'antd';
import { BASE_URL } from '../../utils/config';

// const onChangeCheckBox = (checkedValues) => {
//   console.log('checked = ', checkedValues);
// };

const Booking = ({ tour, avgRating }) => {
  const { price, reviews, title } = tour;
  const navigate = useNavigate();

  const [booking, setBooking] = useState({
    tourName: title,
    fullName: '',
    guestSize: '',
    phone: '',
    bookAt: '',
    email: '',
  });

  const handleChange = (e) => {
    setBooking((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleDateChange = (date, dateString) => {
    setBooking((prev) => ({ ...prev, bookAt: dateString }));
  };

  const serviceFee = 10;
  const totalAmount = Number(price) + Number(serviceFee);

  const orderData = {
    items: [
      {
        name: title,
        sku: '001',
        price: totalAmount.toFixed(2),
        currency: 'USD',
        quantity: 1,
      },
    ],
    total: totalAmount.toFixed(2),
  };

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      const bookingData = {
        tourName: booking.tourName,
        fullName: booking.fullName,
        guestSize: parseInt(booking.guestSize, 10),
        phone: booking.phone,
        bookAt: booking.bookAt,
        email: booking.email,
        payment: orderData,
      };

      const response = await fetch(`${BASE_URL}/booking`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(bookingData),
      });

      const result = await response.json();

      if (!response.ok) {
        return alert(result.message);
      }

      if (result.success && result.approval_url) {
        window.location.href = result.approval_url; // Chuyển người dùng đến PayPal
      } else {
        alert('Error: Could not retrieve PayPal approval URL');
      }
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const bookingId = urlParams.get('bookingId');
      const paymentId = urlParams.get('paymentId');
      const PayerID = urlParams.get('PayerID');

      if (bookingId && paymentId && PayerID) {
        try {
          const response = await fetch(
            `/api/v1/payment/success?bookingId=${bookingId}&paymentId=${paymentId}&PayerID=${PayerID}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          const result = await response.json();

          if (result.success) {
            navigate('/thank-you');
          } else {
            alert('Payment verification failed');
          }
        } catch (err) {
          console.error('Error verifying payment:', err);
          alert('An error occurred while verifying payment.');
        }
      }
    };

    checkPaymentStatus();
  }, [navigate]);

  return (
    <>
      <div className='booking'>
        <h3 className='mb-4'>Book This Tour</h3>
        <DatePicker
          className='w-full'
          onChange={handleDateChange} // Updated to handle date changes
          format='YYYY-MM-DD' // Format date
        />

        <div className='booking__form'>
          <h4>Information</h4>
          <Form className='booking__info-form' onSubmit={handleClick}>
            <FormGroup>
              <input
                type='text'
                placeholder='Enter Full Name'
                id='fullName'
                required
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <input
                type='email'
                placeholder='Enter Email'
                id='email'
                required
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <input
                type='phone'
                placeholder='Enter Phone Number'
                id='phone'
                required
                onChange={handleChange}
              />
            </FormGroup>

            <FormGroup>
              <input
                type='number'
                placeholder='Enter Guest Size'
                id='guestSize'
                required
                min='1'
                step='1'
                onInput={(e) => {
                  e.target.value = e.target.value < 1 ? '' : e.target.value;
                }} // Fixed onInput
                onChange={handleChange}
              />
            </FormGroup>

            <div className='mb-3'>
              <h4>Tickets</h4>
              <div className='grid gap-2'>
                <div className='flex justify-between items-center'>
                  <div>
                    <p className='text-lg mb-0 text-[#6e6e6e] '>Adult (14+ years)</p>
                  </div>

                  <div>
                    <InputNumber min={1} max={10} defaultValue={0} />
                  </div>
                </div>

                <div className='flex justify-between items-center'>
                  <div>
                    <p className='text-lg mb-0 text-[#6e6e6e] '>Youth (13-17 years)</p>
                  </div>

                  <div>
                    <InputNumber min={1} max={10} defaultValue={0} />
                  </div>
                </div>

                <div className='flex justify-between items-center'>
                  <div>
                    <p className='text-lg mb-0 text-[#6e6e6e] '>Children (0-14 years)</p>
                  </div>

                  <div>
                    <InputNumber min={1} max={10} defaultValue={0} />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h4>Means of transport</h4>
              <Checkbox.Group className='w-full mt-2' >
                <Row className='flex gap-2'>
                  <Col className='flex justify-between items-center'>
                    <Checkbox value='A'>Motorbike</Checkbox>
                  </Col>
                  <Col className='flex justify-between items-center'>
                    <Checkbox value='B'>Cycling & Boat trip</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>

            {/* <div>
              <h4>Extra services</h4>
              <Checkbox.Group className='w-full mt-2' onChange={onChangeCheckBox}>
                <Row className='gap-2'>
                  <Col lg='12' className='flex justify-between items-center'>
                    <Checkbox value='A'>Service per booking</Checkbox>
                    <div>
                      <h5 className='mb-0'>$30</h5>
                    </div>
                  </Col>
                  <Col lg='12'>
                    <Checkbox value='B'>Service per person</Checkbox>
                  </Col>

                  <Col lg='12' className='flex justify-between '>
                    <div>
                      <p className='pl-6 text-lg mb-0 text-[#6e6e6e]'>Adult:</p>
                    </div>
                    <div>
                      <h5 className='mb-0'>$30</h5>
                    </div>
                  </Col>

                  <Col lg='12' className='flex justify-between '>
                    <div>
                      <p className='pl-6 text-lg mb-0 text-[#6e6e6e]'>Children:</p>
                    </div>
                    <div>
                      <h5 className='mb-0'>$30</h5>
                    </div>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div> */}

            <div className='flex justify-between items-center mt-4'>
              <p className='mb-0 text-lg'>
                Online Payment <PaymentOutlinedIcon />
              </p>
              <Checkbox />
            </div>

            <Button className='btn primary__btn w-100 mt-4' type='submit'>
              Book Now !!
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Booking;
