
import './App.css';
import Layout from './components/Layout/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';  //Bootstrap Link




function App() 
{
  return (
  <>
     <Layout/>
  </>
  )
}

export default App;
