import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { emphasize, styled } from '@mui/material/styles';
import * as React from 'react';
import { PathClass } from '../../router/constantRouter';
import './Breadcrumbs.css';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function CustomizedBreadcrumbs({ pathName, title }) {
  const pathSegments = pathName.split(' / '); // Tách đường dẫn thành các phần tử
  return (
    <div role='presentation' onClick={handleClick} className='cursor-pointer'>
      <Breadcrumbs
        aria-label='breadcrumb'
        separator={<span className='text-gray-500 text-base'>{'>'}</span>}
        className='space-x-2 '
      >
        {/* Home Breadcrumb */}
        <StyledBreadcrumb
          component='a'
          href={PathClass.rootPath}
          label='Home'
          icon={<HomeIcon fontSize='small' />}
          className='text-blue-600 hover:text-blue-800 text-[16px]'
        />

        {/* Tours Breadcrumb */}
        <StyledBreadcrumb
          component='a'
          href={PathClass.toursPath}
          label='Tours'
          className='text-blue-600 hover:text-blue-800 text-base'
        />

        {/* Title Breadcrumb - Hiển thị tiêu đề của tour nếu có */}
        {title && (
          <StyledBreadcrumb
            label={title} // Hiển thị tiêu đề động
            className='text-blue-600 text-base'
          />
        )}
      </Breadcrumbs>
    </div>
  );
}
