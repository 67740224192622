import React from 'react';
import { Col } from 'reactstrap';
import useFetch from './../../hooks/useFetch.js';
import { BASE_URL } from './../../utils/config.js';
import TourCard from './../../shared/TourCard.jsx';
import { Spin } from 'antd';

  const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;

const FeaturedTourList = () => {

   const { data: featuredTours, loading, error } = useFetch(
      `${BASE_URL}/tours/search/getFeaturedTours`
   );

   return (
     <>
       {loading && (
         <Spin tip='Loading' size='large'>
           {content}
         </Spin>
       )}
       {error && <h4>{error}</h4>}

       {!loading &&
         !error &&
         featuredTours?.map((tour) => (
           <Col lg='3' md='6' sm='6' className='mb-4' key={tour._id}>
             <TourCard tour={tour} />
           </Col>
         ))}
     </>
   );
};

export default FeaturedTourList
