import React, { useContext, useEffect, useRef } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import 'remixicon/fonts/remixicon.css'; //Remixicon Icons
import logo from '../../assets/images/logo_2.png';
import { AuthContext } from '../../context/AuthContext';
import './header.css';

//Create header navigation links array
const nav_links = [
  {
    path: '/home',
    display: 'Home',
  },
  {
    path: '/about',
    display: 'About',
  },
  {
    path: '/tours',
    display: 'Tours',
  },
];

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  };

  const stickyHeaderFunc = () => {
    window.addEventListener('scroll', () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add('sticky__header');
      } else {
        headerRef.current.classList.remove('sticky__header');
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();
    return window.removeEventListener('scroll', stickyHeaderFunc);
  });

  const toggleMenu = () => menuRef.current.classList.toggle('show__menu');

  return (
    <>
      <header className='header relative z-[1]' ref={headerRef}>
        <Container>
          <Row>
            <div className='nav__wrapper d-flex align-items-center justify-content-between'>
              {/*========= Logo =========== */}
              <div className='logo'>
                <img src={logo} alt=' not present' />
              </div>
              {/*========= Logo End =========== */}


              {/*========= Menu Start =========== */}
              <div className='navigation' ref={menuRef} onClick={toggleMenu}>
                <ul className='menu d-flex align-items-center gap-5 '>
                  {nav_links.map((item, index) => (
                    <li className='nav__item' key={index}>
                      <NavLink
                        to={item.path}
                        className={(navClass) =>
                          navClass.isActive ? 'active__link' : ''
                        }
                      >
                        {item.display}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              {/*========= Menu End =========== */}

              <div className='ml-7 nav__right d-flex align-items-center gap-4'>
                <div className='nav__btns d-flex align-items-center gap-4'>
                  {user ? (
                    <>
                      <h5 className='mb-0 username'>{user.username}</h5>
                      <button className=' logout' onClick={logout}>
                        Logout
                      </button>
                    </>
                  ) : (
                    <>
                      <button className='btn secondary__btn'>
                        <Link to='/login'>Login</Link>
                      </button>

                      <button className='btn primary__btn'>
                        <Link to='/register'>Register</Link>
                      </button>
                    </>
                  )}
                </div>
                <span className='mobile__menu' onClick={toggleMenu}>
                  <i className='ri-menu-line'></i>
                </span>
              </div>
            </div>


          </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;
