import { Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CustomizedBreadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import useFetch from '../hooks/useFetch';
import CommonSection from '../shared/CommonSection';
import Newsletter from '../shared/Newsletter';
import SearchBar from '../shared/SearchBar';
import TourCard from '../shared/TourCard';
import { BASE_URL } from '../utils/config';

const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};
const content = <div style={contentStyle} />;

const Tours = () => {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const path = useMemo(
    () => window.location.pathname || '',
    [window.location.pathname]
  );
  const {
    data: tours,
    loading,
    error,
  } = useFetch(`${BASE_URL}/tours?page=${page}`);
  const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`);

  useEffect(() => {
    const pages = Math.ceil(tourCount / 8); //Fetching tour count from this above URL
    setPageCount(pages);
    window.scrollTo(0, 0);
  }, [page, tourCount, tours]);

  return (
    <>
      <CommonSection title={'All Tours'} />
      <section className='pb-0'>
        <Container>
          <Row>
            <CustomizedBreadcrumbs pathName={path} />
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>

      <section className='pt-0'>
        <Container>
          {loading && (
            <Spin tip='Loading' size='large'>
              {content}
            </Spin>
          )}
          {error && <h4 className='text-center pt-5'>{error}</h4>}

          {!loading && !error && (
            <Row>
              {tours?.map((tour) => (
                <Col lg='3' md='6' sm='6' className='mb-4' key={tour._id}>
                  <TourCard tour={tour} />
                </Col>
              ))}

              <Col lg='12'>
                <div className='pagination d-flex align-items-center justify-content-center mt-4 gap-3'>
                  {[...Array(pageCount).keys()].map((number) => (
                    <span
                      key={number}
                      onClick={() => setPage(number)}
                      className={page === number ? 'active__page' : ''}
                    >
                      {number + 1}
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </section>
      <Newsletter />
    </>
  );
};

export default Tours;
