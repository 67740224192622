import React, { useRef } from 'react';
import './searchbar.css';
import { Col, Form, FormGroup } from 'reactstrap';
import { BASE_URL } from '../utils/config';
import {useNavigate} from 'react-router-dom';
import useNotification from '../utils/notification'; 
const SearchBar = () => {

    const locationRef = useRef('');

    const maxGroupSizeRef = useRef(0);
    const navigate = useNavigate();
    const { openNotification, contextHolder } = useNotification();

    {/*To hold current pass value by user */ }
    
    const searchHandler = async () => {
        const location = locationRef.current.value;
        const maxGroupSize = maxGroupSizeRef.current.value;

        // Validation: All fields are mandatory
        if (location === '' || maxGroupSize === "") {
            openNotification("Validation Error", "All Fields are Required!");
            return;
        }

        try {
            const res = await fetch(`${BASE_URL}/tours/search/getTourBySearch?city=${location}&maxGroupSize=${maxGroupSize}`);

            if (!res.ok) {
                openNotification("Network Error", "Something went wrong while fetching the data!");
                return;
            }

            const result = await res.json();
            navigate(`/tours/search?city=${location}&maxGroupSize=${maxGroupSize}`, { state: result.data });
        } catch (error) {
            openNotification("Fetch Error", "An error occurred while processing your request.");
        }
    };
    return (
        <Col lg='12'>
            <div className="search__bar">
                <Form className='d-flex align-items-center gap-4'>
                   
                    {/*******  1) Location **********/}
                    <FormGroup className='d-flex gap-3 form__group form__group-fast override-mb' >
                        <span><i className="ri-map-pin-line"></i></span>
                        <div>
                            <h6>Location</h6>
                            <input type='text' placeholder='Where are you going??' ref={locationRef} />
                        </div>
                    </FormGroup> 

 

                    {/******* 3) Max People **********/}
                    <FormGroup className='d-flex gap-3 form__group form__group-last override-mb' >
                        <span><i className="ri-group-line"></i></span>
                        <div>
                            <h6>Max People</h6>
                            <input type='number' placeholder='0' ref={maxGroupSizeRef} />
                        </div>
                    </FormGroup>

                    {/*Search icon */}
                    <span className="search__icon" type='submit' onClick={searchHandler} >
                        <i className="ri-search-eye-line"></i>
                    </span>
                </Form>
            </div>
        </Col>
    )
}

export default SearchBar