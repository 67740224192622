import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './../pages/Home';
import Tours from './../pages/Tours';
import Login from './../pages/Login';
import Register from './../pages/Register';
import SearchResultList from './../pages/SearchResultList';
import TourDetails from './../pages/TourDetails';
import ThankYou from '../pages/ThankYou';
import { PathClass } from './constantRouter';

const Routers = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to={PathClass.rootPath} />} />
      <Route path={PathClass.rootPath} element={<Home />} />
      <Route path={PathClass.toursPath} element={<Tours />} />
      <Route path={PathClass.tourDetailPath} element={<TourDetails />} />
      <Route path={PathClass.loginPath} element={<Login />} />
      <Route path={PathClass.registerPath} element={<Register />} />
      <Route path={PathClass.thankYouPath} element={<ThankYou />} />
      <Route path={PathClass.searchResultPath} element={<SearchResultList />} />
    </Routes>
  );
}

export default Routers;


