import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Carousel, Input, notification, Rate, Spin, Timeline } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Form, ListGroup, Row } from 'reactstrap';
import avatar from '../assets/images/avatar.jpg';
import Booking from '../components/Booking/Booking.jsx';
import CustomizedBreadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Newsletter from '../shared/Newsletter.jsx';
import '../styles/tourdetails.css';
import calculateAvgRating from '../utils/avgRating';
import { AuthContext } from './../context/AuthContext.js';
import useFetch from './../hooks/useFetch.js';
import { BASE_URL } from './../utils/config.js';

const TourDetails = () => {
  const { id } = useParams();
  const reviewMsgRef = useRef('');
  const [tourRating, setTourRating] = useState(0);
  // const { user } = useContext(AuthContext);
  const [reviews, setReviews] = useState([]);
  const [userName, setUserName] = useState('');
  //fetch data from database
  const { data: tour, loading, error } = useFetch(`${BASE_URL}/tours/${id}`);

  //Destructor properties from tour project
  const {
    
    title,
    tour_desc,
    price,
    reviews: initialReviews,
    city,
    maxGroupSize,
    images,
    tour_time,
    Included_excluded,
    Itineraies,
    highlights_descriptions,
    tour_timeLine,
    what_to_bring,
    know_before_you_go,

  } = tour;

  const Included = Included_excluded?.Included;
  const Excluded = Included_excluded?.Excluded;

  const formattedTimeline = Array.isArray(tour_timeLine)
    ? tour_timeLine.map((item) => ({
        children: item,
      }))
    : [];

  const { totalRating, avgRating } = calculateAvgRating(reviews);

  //Date Format
  const options = { day: 'numeric', moth: 'long', year: 'numeric' };

  useEffect(() => {
    if (initialReviews) {
      setReviews(initialReviews);
    }
  }, [initialReviews]);

  const openNotification = (type, message) => {
    notification[type]({
      message: message,
      placement: 'topRight',
      duration: 3,
    });
  };

  const breadcrumbPath = `tours / ${title}`;

  // console.log(breadcrumbPath)
  //submit request to the server
  const submitHandler = async (e) => {
    e.preventDefault();
    const reviewText = reviewMsgRef.current.value;

    try {
      if (!userName) {
        openNotification(
          'error',
          'Please enter your name before submitting the review.'
        );
        return;
      }
      // Đặt tên người dùng mặc định nếu không đăng nhập
      const reviewObj = {
        username: userName,
        reviewText,
        rating: tourRating,
      };

      const res = await fetch(`${BASE_URL}/review/${id}`, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(reviewObj),
      });

      const result = await res.json();
      if (!res.ok) {
        openNotification('error', result.message);
        return;
      }
      openNotification('success', result.message);
      setReviews((prevReviews) => [
        ...prevReviews,
        { ...reviewObj, createdAt: new Date() },
      ]);
      reviewMsgRef.current.value = '';
      setUserName('');
      setTourRating(0);
    } catch (err) {
      openNotification('error', err.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tour]);

  const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;

  return (
    <>
      <section>
        <Container>
          <Row>
            <CustomizedBreadcrumbs pathName={breadcrumbPath} title={title} />
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          {loading && (
            <Spin tip='Loading' size='large'>
              {content}
            </Spin>
          )}
          {error && <h4 className='text-center pt-5'> {error} </h4>}
          {!loading && !error && (
            <>
              <Row>
                <Col lg='12'>
                  <div className='tour__info'>
                    <h2>{title}</h2>

                    <div className='tour__extra_details'>
                      <span className='text-lg'>
                        <LocationOnOutlinedIcon className='text-[#0a6847]' />
                        {city}{' '}
                      </span>
                      <span className='text-lg'>
                        <MonetizationOnOutlinedIcon className='text-[#0a6847]' />{' '}
                        ${price}
                        /Per Person{' '}
                      </span>

                      <span className='text-lg'>
                        <PeopleAltOutlinedIcon className='text-[#0a6847]' />
                        {maxGroupSize} Max People
                      </span>

                      <span className='text-lg'>
                        <CalendarMonthOutlinedIcon className='text-[#0a6847]' />
                        {tour_time} days
                      </span>

                      <span className='tour__rating d-flex align-items-center gap-1 text-lg'>
                        <i
                          className='ri-star-fill'
                          style={{ color: 'var(--secondary-color)' }}
                        ></i>
                        {avgRating === 0 ? null : avgRating}
                        {totalRating === 0 ? (
                          'Not rated'
                        ) : (
                          <span> ({reviews?.length})</span>
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg='8'>
                  <div className='tour__content'>
                    <Carousel className='mb-5' arrows infinite={false}>
                      {images && images.length > 0 ? (
                        images.map((image, index) => (
                          <div className='h-[641px]' key={index}>
                            {' '}
                            {/* Mỗi <div> đại diện cho một slide */}
                            <img
                              src={image}
                              alt={`Tour image ${index + 1}`}
                              className='w-full h-full object-cover rounded-3xl'
                            />
                          </div>
                        ))
                      ) : (
                        <p className='text-center text-gray-500'>
                          No images available
                        </p>
                      )}
                    </Carousel>

                    <div className='border-[1px] rounded-lg p-8 tour_content'>
                      <div>
                        <h4>Description</h4>
                        <p className='text-lg'> {tour_desc} </p>
                      </div>

                      <div className='grid gap-2'>
                        <h4>Highlights</h4>
                        {highlights_descriptions &&
                        highlights_descriptions.length > 0 ? (
                          highlights_descriptions.map((highlight, index) => (
                            <div className='flex gap-2 text-lg' key={index}>
                              <CheckCircleRoundedIcon className='text-[#0a6847]' />{' '}
                              {highlight}
                            </div>
                          ))
                        ) : (
                          <p>No highlights available</p>
                        )}
                      </div>
                      <div className='grid gap-2'>
                        <h4 className='mt-[16px]'>What to bring</h4>
                        {what_to_bring && what_to_bring.length > 0 ? (
                          what_to_bring.map((include, index) => (
                            <div className='flex gap-2 text-lg' key={index}>
                              <CheckRoundedIcon className='text-[#0a6847]' />{' '}
                              {include}
                            </div>
                          ))
                        ) : (
                          <p>No Included excluded available</p>
                        )}
                      </div>
                      <div className='grid gap-2'>
                        <h4 className='mt-[16px]'>Know before you go</h4>
                        {know_before_you_go && know_before_you_go.length > 0 ? (
                          know_before_you_go.map((include, index) => (
                            <div className='flex gap-2 text-lg' key={index}>
                              <CheckRoundedIcon className='text-[#0a6847]' />{' '}
                              {include}
                            </div>
                          ))
                        ) : (
                          <p>No Included excluded available</p>
                        )}
                      </div>
                      <div className='grid gap-2'>
                        <h4 className='mt-[16px]'>What’s Included</h4>
                        {Included && Included.length > 0 ? (
                          Included.map((include, index) => (
                            <div className='flex gap-2 text-lg' key={index}>
                              <CheckRoundedIcon className='text-[#0a6847]' />{' '}
                              {include}
                            </div>
                          ))
                        ) : (
                          <p>No Included excluded available</p>
                        )}

                        {Excluded && Excluded.length > 0 ? (
                          Excluded.map((Exclude, index) => (
                            <div className='flex gap-2 text-lg' key={index}>
                              <CloseIcon className='text-red-600' />
                              {Exclude}
                            </div>
                          ))
                        ) : (
                          <p className='text-lg'>
                            No Excluded excluded available
                          </p>
                        )}
                      </div>

                      <div>
                        <h4 className='mt-[16px]'>Itinerary</h4>
                        <p className='text-lg'>{Itineraies}</p>
                      </div>

                      <div>
                        <h4 className='mt-[16px] mb-[20px]'>Tour plan</h4>
                        <div className='text-lg'>
                          <Timeline items={formattedTimeline} />
                        </div>
                      </div>
                    </div>

                    {/* Tour Reviews aection Start */}
                    <div className='tour__reviews mt-4 mb-0'>
                      <h4> Reviews ({reviews?.length} reviews) </h4>

                      <Form onSubmit={submitHandler}>
                        <div className='mb-4'>
                          <Input
                            className='border-[#fbc16d] w-1/3 rounded-3xl'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder='Enter your name'
                            required
                          />
                        </div>

                        <Rate
                          className='mb-4'
                          onChange={(value) => setTourRating(value)}
                          value={tourRating}
                        />

                        <div className='review__input'>
                          <input
                            type='text'
                            ref={reviewMsgRef}
                            placeholder='Share Your thoughts'
                            required
                          />
                          <button
                            className='btn primary__btn text-white'
                            type='submit'
                          >
                            Submit
                          </button>
                        </div>
                      </Form>

                      <ListGroup className='user__reviews'>
                        {reviews?.map((review, index) => (
                          <div key={index} className='review__item'>
                            {' '}
                            <img src={avatar} alt='Image not found' />
                            <div className='w-100'>
                              <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                  <h5>{review.username} </h5>
                                  <p className='text-lg'>
                                    {' '}
                                    {new Date(review.createdAt).toDateString(
                                      'en-US',
                                      options
                                    )}
                                  </p>
                                </div>
                                <span className='d-flex align-items-center'>
                                  {review.rating}{' '}
                                  <i className='ri-star-fill'></i>
                                </span>
                              </div>
                              <h6>{review.reviewText}</h6>
                            </div>
                          </div>
                        ))}
                      </ListGroup>
                    </div>

                    {/* Tour Reviews aection End */}
                  </div>
                </Col>

                <Col lg='4'>
                  <Booking tour={tour} avgRating={avgRating} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>
      <Newsletter />
    </>
  );
};

export default TourDetails;
